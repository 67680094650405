import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { menuItems } from '../menuItems';

const NavLinks: React.FC = () => {

  return (
    <nav className="hidden lg:flex items-center space-x-8 ml-auto font-redsox text-cream relative">
      {menuItems.map((item) => (
        <div key={item.name} className="relative">
          <NavLink to={item.path} className="relative">
              <div className="relative flex flex-col items-center">
                <span 
                  className={`relative z-10 `} 
                >
                  {item.name}
                </span>
              </div>
          </NavLink>
        </div>
      ))}
      <div
        className="absolute left-[-10%] right-[-10%] bottom-[-20px] h-0.5 bg-cream" // Adjust bottom for vertical spacing
      />
    </nav>
  );
};

export default NavLinks;