import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';
import { toast } from 'react-toastify';

class ContactFormStore {
  name: string = '';
  email: string = '';
  phone: string = '';
  subject: string = 'General Inquiry';
  message: string = '';
  isSubmitting: boolean = false;
  isSuccess: boolean | null = null;
  errorMessage: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  // Explicitly bind the type of 'this' to ContactFormStore
  setField<K extends keyof ContactFormStore>(this: ContactFormStore, field: K, value: ContactFormStore[K]) {
    runInAction(() => {
      this[field] = value;
    });
  }

  async submitForm() {
    runInAction(() => {
      this.isSubmitting = true;
      this.isSuccess = null;
      this.errorMessage = '';
    });

    try {
      const response = await API.post("/api/contact/", {
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message,
      });

      runInAction(() => {
        if (response.status === 201) {
          this.isSuccess = true;
          this.resetForm();
          toast.success("Your message was successfully sent!");
        } else {
          this.isSuccess = false;
          this.errorMessage = "Something went wrong. Please try again.";
          toast.error(this.errorMessage);
        }
      });
    } catch (error: any) {
      runInAction(() => {
        this.isSuccess = false;
        this.errorMessage = error.response?.data?.detail || "Server error. Please try again later.";
        toast.error(this.errorMessage);
      });
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  }

  resetForm() {
    runInAction(() => {
      this.name = '';
      this.email = '';
      this.phone = '';
      this.subject = 'General Inquiry';
      this.message = '';
    });
  }
}

const contactFormStore = new ContactFormStore();
export default contactFormStore;