import React from 'react';

interface DescriptionTextProps {
    text: string;
}

const DescriptionText: React.FC<DescriptionTextProps> = ({ text }) => {
    return (
        <p className="font-myriad text-lg lg:text-xl text-center text-redz-white max-w-3xl mx-auto mt-8 leading-relaxed">
            {text}
        </p>
    );
};

export default DescriptionText;