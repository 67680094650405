import React from 'react';
import { motion } from 'framer-motion';

interface LocationSectionProps {
  title?: string;
  description?: string;
}

const LocationSection: React.FC<LocationSectionProps> = ({ 
  title = 'LOCATION', 
  description 
}) => {
  return (
    <section className="relative bg-redz-white overflow-y-hidden">
      {/* Header Border */}
      <div className="absolute w-[120%] top-[-4.4em] translate-x-[-10%] z-10">
        <img src={"https://redz-bucket.s3.amazonaws.com/LocationHeaderBorder.svg"} alt="Location Header Border" className="w-full" />
      </div>

      <div className="container mx-auto py-16 lg:py-24 px-8 lg:px-16">
        {/* Two Columns */}
        <div className="flex flex-col lg:flex-row justify-between">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 text-center space-y-8">
            {/* Title */}
            <h2 className="font-nexa text-redz-red text-6xl">{title}</h2>

            {/* Optional Description */}
            {description && (
              <p className="font-redsox text-black text-lg lg:text-xl">
                {description}
              </p>
            )}

            {/* Address */}
            
              
            
            <p className="font-redsox text-black text-lg lg:text-xl">
              YONA REDZ 1230 STATE ST. SUITE C, SANTA BARBARA, CA 93101
            </p>

            {/* Location Image */}
            <motion.a
              href='https://www.google.com/maps/dir//1230+State+St,+Santa+Barbara,+CA+93101/'
              whileHover={{ scale: 1.05 }} // Slightly increase the size on hover
              whileTap={{ scale: 0.95 }}   // Slightly decrease the size on tap
              className="block w-full transition-transform duration-300 ease-in-out" // Ensures smooth transition
            >
              <img
                src={"https://redz-bucket.s3.us-west-2.amazonaws.com/RedzLocation.webp"}
                alt="Yona Redz Location"
                className="mt-8 w-full object-cover" // w-full ensures full-width and object-cover maintains aspect ratio
              />
            </motion.a>
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-1/2 text-center space-y-8 mt-12 lg:mt-0">
            {/* Phone Number */}
            <h2 className="font-nexa text-black text-6xl">
              <span className="font-myriad">(</span>805<span className="font-myriad">)</span>679-5657
            </h2>

            {/* Email */}
            <p className="font-nexa text-redz-red text-2xl w-full break-words overflow-hidden">
              YONAREDZSB<span className="font-myriad">@</span><wbr/>YAHOO.COM
            </p>

            {/* Hours Section */}
            <div className="flex justify-center items-center mt-12">
              <h3 className="font-nexa text-black text-3xl">HOURS</h3>
              <img src={"https://redz-bucket.s3.amazonaws.com/Maraccas.svg"} alt="Maracas" className="w-16 h-16 ml-4" />
            </div>

            {/* Hours List */}
            <div className="font-redsox text-black text-lg text-center space-y-4">
              <p>
                Monday - Thursday<br />
                11:00AM - 9:00PM
              </p>
              <p>
                Friday - Saturday<br />
                11:00AM - Midnight
              </p>
              <p>
                Sunday<br />
                11:00AM - 9:00PM
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Border */}
      <div className="w-full translate-y-[10%]">
        <img src={"https://redz-bucket.s3.amazonaws.com/CactusFooter.svg"} alt="Cactus Footer" className="w-full mt-16" />
      </div>
    </section>
  );
};

export default LocationSection;