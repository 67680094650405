import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as FireBallLeft } from '../../../assets/images/Fireball Left.svg';
import { ReactComponent as FireBallRight } from '../../../assets/images/Fireball Right.svg';

const MiddleSection: React.FC = () => (
    <motion.div
        className="relative mb-4 py-2 items-center justify-center mx-auto"
        initial={{ opacity: 0, scale: 0.5, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ delay: 2, duration: 1 }}
    >
        <div className="flex items-center justify-center font-redsox text-red-500 mt-2">
            <FireBallLeft className="w-auto h-8 md:h-12" style={{ width: 'auto', height: '2.5rem' }} />
            <span className="text-2xl md:text-4xl mx-2">Experience</span>
            <FireBallRight className="w-auto h-8 md:h-12" style={{ width: 'auto', height: '2.9rem' }} />
        </div>
        <div className="flex items-center justify-center font-redsox text-red-500 mt-5">
            <span className="text-2xl md:text-4xl">Yona Redz</span>
        </div>
    </motion.div>
);

export default MiddleSection;