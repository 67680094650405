import React from 'react';
// Import the top divider SVG
import { ReactComponent as TacoTuesdayCateringDivider } from '../../../assets/images/TacoTuesday/TacoTuedayCateringDivide.svg';
// Import the CateringTextBlock component
import CateringTextBlock from './CateringTextBlock';
// Import the ClippedImage component
import ClippedImage from './ClippedImage';
// Import the image for the right side

const CateringInfo: React.FC = () => {
    return (
        <section className="bg-redz-red text-redz-white relative h-auto lg:h-screen">
            {/* Top Divider */}
            <div className="absolute top-[-6vw] w-full overflow-x-hidden">
                <TacoTuesdayCateringDivider className="w-[120vw] h-auto" />
            </div>

            {/* Content Container */}
            <div className="flex flex-col lg:flex-row justify-between items-center h-full px-4 space-y-6 lg:space-y-0 lg:space-x-12">
                
                {/* CateringTextBlock Component */}
                <CateringTextBlock />
                
                {/* ClippedImage Component */}
                <div className="hidden lg:block flex-none w-1/2 lg:w-[35%] mx-auto lg:mx-0 order-first lg:order-last">
                    <ClippedImage imageUrl={"https://redz-bucket.s3.amazonaws.com/QuestotacoPlatter.webp"} />
                </div>
            </div>
        </section>
    );
};

export default CateringInfo;