import { motion } from 'framer-motion';
import React from 'react';

const Logo: React.FC = () => (
  <motion.a
    className="flex items-center cursor-pointer"
    whileHover={{ scale: 1.1 }} // Increase size by 10% on hover
    whileTap={{ scale: 0.9 }} // Decrease size by 10% on tap
    href='/'
  >
    <img src="https://redz-bucket.s3.us-west-2.amazonaws.com/logo.webp" alt="Yona Redz Logo" className="h-24 w-auto" />
  </motion.a>
);

export default Logo;