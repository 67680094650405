import React from "react";
import HomeHero from "./HomeHero";
import SignatureDishesSection from "./SignatureDishes";
import TacoTuesdaySection from "./TacoTuesday";
import CateringInfo from "./CateringInfo";
import PrivateEventsSection from "./PrivateEventsSection";
import Gallery from "./Gallery";
import EnjoyRedzAtHomeSection from "./EnjoyRedzAtHome";
import GiftCardsSection from "./GiftCardsSection";
import StayUpdatedSection from "./StayUpdated";
import LocationSection from "../common/LocationSection";

const Home: React.FC = () => (
    <div>
        <HomeHero />
        <SignatureDishesSection />
        <TacoTuesdaySection />
        <CateringInfo />
        <PrivateEventsSection />
        <Gallery/>
        <EnjoyRedzAtHomeSection />
        <GiftCardsSection/>
        <StayUpdatedSection/>
        <LocationSection />
    </div>
);

export default Home;