import React from 'react';
import { NavLink } from 'react-router-dom';
import { menuItems } from '../menuItems'; // Adjust the path as needed

const FooterNavLinks: React.FC = () => {
  return (
    <nav className="flex flex-row items-center justify-center space-x-4 font-redsox text-redz-white">
      {menuItems.map((item) => (
        <NavLink 
          key={item.name} 
          to={item.path} 
        >
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default FooterNavLinks;