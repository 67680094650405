import React, { Suspense } from 'react';
import Root from './pieces/Root';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from './pieces/Root/ErrorBoundary';
import Home from './pieces/Home';
import ContactUs from './pieces/ContactUs';


const App = () => {
  return (
    <Root>
      <ErrorBoundary>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/contact-us" element={<ContactUs />}/>

              {/* <Route path="/contact-us" element={<ContactUs />}/>
              <Route path="/about-us" element={<AboutUs />}/>
              <Route path="/catering" element={<CateringSection />}/>
              <Route path="/menus" element={<Menu />}>
                <Route path="sb" element={<SantaBarbaraMenu />} />
                <Route path="np" element={<NewburyParkMenu />} />
              </Route>
              <Route path="/locations" element={<Locations />}>
                <Route path="sb" element={<SantaBarbaraLocation />} />
                <Route path="np" element={<NewburyParkLocation />} />
              </Route> */}
            </Routes>
        </Suspense>
        </ErrorBoundary>
    </Root>
  );
};

export default App;