import React from 'react';
import { motion } from 'framer-motion';
// Import the left divider SVG
import { ReactComponent as HomeCateringDiv } from '../../../assets/images/Catering/HomeCateringDiv.svg';
// Import the Button component as an SVG
import Button from '../../common/Button';

const CateringTextBlock: React.FC = () => {
    return (
        <div className="relative flex flex-col items-center justify-center w-full h-full overflow-hidden">
            {/* HomeCateringDiv */}
            <div className="absolute left-0 top-[-5%] bottom-[-5%] h-[110%] w-auto flex-none">
                <HomeCateringDiv className="h-full w-auto" />
            </div>

            {/* Text Content */}
            <div className="relative flex flex-col items-center justify-center text-center space-y-6 px-8 lg:space-y-8 z-10 px-4 lg:px-8 py-8 lg:py-12 max-w-4xl">
                <h2 className="font-nexa text-black text-4xl lg:text-6xl xl:text-8xl uppercase">
                    EXCEPTIONAL CATERING FOR EVERY OCCASION
                </h2>
                <p className="font-myriad text-lg lg:text-xl max-w-prose">
                    Our catering services bring the rich flavors of authentic Mexican cuisine to your special events. Whether it's a wedding, corporate gathering, or family reunion, we offer customized packages that ensure quality and reliability for any occasion.
                </p>
                <p className="font-myriad text-lg lg:text-xl max-w-prose">
                    Choose from our popular catering packages, including the Taco Package, Quesotaco Package, and Premium Package. Each package offers a selection of our most beloved dishes, such as street tacos, quesotacos, and quesadillas, along with fresh salsas, consome, and sides. We also provide Aguas Frescas and a guest menu with the Premium Package.
                </p>

                {/* Button with Text Overlay */}
                <motion.a 
                    href="/contact-us" 
                    className="relative inline-flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <div className='relative w-48 lg:w-64 h-12 lg:h-16 px-4 lg:px-6 flex items-center justify-center'>   
                        {/* Ensure the SVG is displayed correctly */}
                        <Button color="#000000" className="absolute w-full h-full" />
                        <span className="absolute inset-0 flex items-center justify-center font-redsox text-redz-white uppercase text-lg lg:text-xl leading-none">
                            BOOK YOUR EVENT NOW
                        </span>
                    </div>
                </motion.a>
            </div>
        </div>
    );
};

export default CateringTextBlock;