import React from 'react';
import FooterLogo from './FooterLogo';
import FooterNavLinks from './FooterNavLinks';
// Import the FooterLine SVG as a React component
import { ReactComponent as FooterLine } from '../../../assets/images/FooterLine.svg';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-redz-white px-6 py-8 xl:px-56 xl:py-24 relative z-10 overflow-y-hidden">
      <div className="relative z-10 flex flex-col items-center justify-center space-y-8">
        <FooterLogo />
        <FooterNavLinks />
      </div>
      {/* FooterLine SVG positioned at the bottom */}
      <div className="absolute bottom-[-1.5em] left-0 w-full overflow-x-hidden">
        <FooterLine className="w-full h-auto" />
      </div>
    </footer>
  );
};

export default Footer;