import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import NavLinks from './HeaderNavLinks';
import MobileMenu, { MobileMenuButton } from './MobileMenu';
import { motion } from 'framer-motion';
import {ReactComponent as OrderOnlineTag} from '../../../assets/images/Order-Online-Tag.svg'; // Import the SVG as a regular image

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const location = useLocation();
  const { pathname } = location;

  return pathname !== '/login' && pathname !== '/reset-password' && pathname !== '/register' && pathname !== '/confirmation-instruction' ? (
    <>
      <header className="fixed top-0 left-0 w-full bg-black text-white flex items-center justify-between px-6 md:px-24 py-4 md:py-6 z-50">
        <div className="flex items-center space-x-12">
          {/* Logo */}
          <Logo />
          {/* Navigation Links for large screens */}
          <div className="hidden lg:flex items-center space-x-8">
            <NavLinks />
          </div>
        </div>
        {/* Social Media Icons and Order Online Tag */}
        <div className="hidden lg:flex items-center space-x-6"> {/* Hide on mobile */}
          {/* Social Media Icons */}
          <motion.a 
            href="https://www.instagram.com/yona_redz/" 
            target="_blank" 
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }} // Increase size on hover
            whileTap={{ scale: 0.9 }} // Decrease size on tap
            className="flex items-center"
          >
            <img src={"https://redz-bucket.s3.us-west-2.amazonaws.com/redz-instagram-logo.svg"} alt="Instagram" className="h-8 w-auto" />
          </motion.a>
          <motion.a 
            href="https://www.facebook.com/p/Yona-Redz-100030957202648/" 
            target="_blank" 
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }} // Increase size on hover
            whileTap={{ scale: 0.9 }} // Decrease size on tap
            className="flex items-center"
          >
            <img src={"https://redz-bucket.s3.us-west-2.amazonaws.com/redz-fb-logo.svg"} alt="Facebook" className="h-8 w-auto" />
          </motion.a>
          {/* Order Online Tag */}
          <a href="https://direct.chownow.com/order/28301/locations/41891" target="_blank" rel="noopener noreferrer">
            <OrderOnlineTag className="h-16 w-auto" />
          </a>
        </div>
        {/* Mobile Menu Button */}
        <div className="lg:hidden"> {/* Visible only on mobile */}
          <MobileMenuButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
        </div>
      </header>
      {/* Mobile Menu */}
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} toggleModal={toggleModal} />
    </>
  ) : null;
};

export default Header;