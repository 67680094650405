import React from "react";
import StayUpdatedSVG from "../../common/StayUpdatedSVG";
import NewsletterButton from "../../common/NewletterButton";

const Newsletter: React.FC = () => (
  <div className="flex flex-col items-center justify-center px-8 lg:px-24 pb-12 md:pb-24"> {/* Added padding */}
    {/* StayUpdatedSVG Component */}
    <div className="w-full max-w-screen-lg pb-12">
      <StayUpdatedSVG
        title="Join our newsletter"
        titleColor="#EE3337"
        description="Don't miss out on exclusive updates and offers. Sign up for our newsletter today!"
        elementsColor="#F0E2CB"
        fontMultiple={0.5}
        optionalSection={false}
      />
    </div>

    {/* NewsletterSignup Component */}
    <div className="mt-8 w-full lg:w-1/3">
      <NewsletterButton color="redz-red" />
    </div>
  </div>
);

export default Newsletter;