import React from 'react';
import GiftCardsSVG from './GiftCardsSVG'; // Assuming you saved the SVG component here

const GiftCardsSection: React.FC = () => {
    return (
        <section className="relative">
            {/* Top Border SVG */}
            <div className="absolute w-[120%] top-[-2em] translate-x-[-10%] z-10">
                <img src={"https://redz-bucket.s3.amazonaws.com/GiftCardTopBorder.svg"} alt="Gift Card Top Border" className="w-full" />
            </div>

            <div className="relative bg-black text-center py-16 lg:py-24 px-0 lg:px-[10%] z-20 mt-[-100px]">
                {/* Gift Cards SVG */}
                <div className="relative mx-auto max-w-full lg:max-w-[80%] mt-8">
                    <GiftCardsSVG />
                </div>
            </div>
        </section>
    );
};

export default GiftCardsSection;