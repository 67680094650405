import React from "react";
import { motion } from "framer-motion";
import WelcomeSection from "./WelcomeSection";
import MiddleSection from "./MiddleSection";
import CulinaryCreationsSection from "./CulinaryCreationsSection";
import GoToMenuSection from "./GoToMenuSection";
import RightSection from "./RightSection";

const HomeHero: React.FC = () => (
    <div className="h-screen p-0 bg-black flex flex-col lg:flex-row items-center justify-center relative z-0">
        {/* Left Section */}
        <div className="flex flex-col justify-center items-center w-full lg:w-1/2 h-full z-10">
            <motion.div
                className="text-white flex flex-col items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <WelcomeSection />
                <MiddleSection />
                <CulinaryCreationsSection />
                <GoToMenuSection />
            </motion.div>
        </div>

        {/* Right Section */}
        <RightSection />
    </div>
);

export default HomeHero;