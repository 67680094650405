import React from 'react';
import { motion } from 'framer-motion';
import {ReactComponent as AtHome} from '../../../assets/images/AtHome.svg';
import Button from '../../common/Button';

const EnjoyRedzAtHomeSection: React.FC = () => {
    return (
        <section
            className="relative bg-cover bg-center text-white py-16 lg:py-24 mb-12"
            style={{ backgroundImage: `url('https://redz-bucket.s3.amazonaws.com/bgEnjoyRedzAtHome.webp')` }}
        >
            {/* Title */}
            <div className="container mx-auto px-4 text-center mt-[8vh]">
                <div className="flex items-center justify-center space-x-4">
                    <h2 className="font-nexa text-black text-4xl lg:text-6xl uppercase">
                        ENJOY YONA REDZ
                    </h2>
                    <AtHome className="w-32 lg:w-48" />
                </div>
            </div>

            {/* Description */}
            <div className="container mx-auto px-4 text-center mt-8 lg:mt-12 max-w-3xl">
                <p className="font-myriad text-lg lg:text-xl leading-relaxed text-black">
                    Enjoy the delicious flavors of Yona Redz from the comfort of your home with our convenient takeout and delivery services. Whether you're craving our famous birria tacos, mouth-watering quesadillas, or a refreshing agua fresca, you can now have your favorite dishes delivered straight to your door.
                    <br /><br />
                    Ordering online is easy, and our dedicated team ensures that every dish is prepared with the same quality and care you expect from Yona Redz.
                </p>

                {/* Call to Action */}
                <motion.a 
                    href="https://direct.chownow.com/order/28301/locations/41891" 
                    className="relative inline-flex items-center justify-center mt-12"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <div className='relative w-48 lg:w-64 h-12 lg:h-16 px-4 lg:px-6 flex items-center justify-center mb-[3vh]'>
                        <Button color="#EE3337" className="absolute w-full h-full" />
                        <span className="absolute inset-0 flex items-center justify-center font-redsox text-redz-white uppercase text-xl lg:text-2xl leading-none">
                            ORDER ONLINE
                        </span>
                    </div>
                </motion.a>
            </div>
        </section>
    );
};

export default EnjoyRedzAtHomeSection;