import React from 'react';
// Import the SVGs
import { ReactComponent as TacoSvg } from '../../../assets/images/TacoTuesday/Taco.svg';
import { ReactComponent as AllDayBannerSvg } from '../../../assets/images/TacoTuesday/All_Day_Banner.svg';

const TacoTuesdaySection: React.FC = () => {
    return (
        <section className="bg-black text-redz-white relative flex flex-col lg:flex-row items-center justify-center py-10 lg:py-20">
            <div className="container flex flex-col lg:flex-row items-center">
                {/* Left Taco Image */}
                <div className="flex-none w-full lg:w-[50%] mb-8 lg:mb-0 lg:ml-[-10%]">
                    <TacoSvg className="w-full h-auto" />
                </div>

                {/* Right Content */}
                <div className="flex-1 flex flex-col items-center justify-center text-center lg:text-center lg:ml-8">
                    {/* All Day Banner SVG */}
                    <AllDayBannerSvg className="w-full max-w-xs md:max-w-md mx-auto h-auto mb-4 lg:mb-8" />
                    
                    {/* TACO TUESDAY Text */}
                    <h2 className="font-nexa text-redz-red text-4xl md:text-5xl lg:text-6xl mb-4 lg:mb-8">
                        TACO TUESDAY
                    </h2>
                    
                    {/* Queso Tacos and Street Tacos Prices */}
                    <p className="font-redsox text-redz-white text-2xl md:text-3xl lg:text-4xl text-center">
                        $4 Queso Tacos&nbsp;
                        <br className="block xl:hidden" />
                        &nbsp;$2.50 Street Tacos
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TacoTuesdaySection;