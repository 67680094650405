import React from 'react';
import SignatureDishesCarousel from './SignatureDishesCarousel';
// Import the top and bottom SVG dividers
import { ReactComponent as HeroSignatureDishDivider } from '../../../assets/images/Hero-signature-dish-divider.svg';
import { ReactComponent as TacoTuesdayDivider } from '../../../assets/images/signature-dish-taco-tuesday-divider.svg';
// Import the left and right title accents
import { ReactComponent as SignatureDishTacoLeft } from '../../../assets/images/signature-dish-taco-left.svg';
import { ReactComponent as SignatureDishTacoRight } from '../../../assets/images/signature-dish-taco-right.svg';

const SignatureDishesSection: React.FC = () => {
    return (
        <section className="bg-redz-white text-redz-red relative">
            {/* Top Divider */}
            <div className="relative top-[-3vw] w-full overflow-x-hidden">
                <HeroSignatureDishDivider className="w-[120vw] h-auto" />
            </div>

            <div className="container mx-auto text-center relative z-10">
                <div className="flex items-center justify-center mb-8">
                    {/* Left Accent */}
                    <SignatureDishTacoLeft className="w-[6vw] h-auto mr-4" />
                    <h2 className="text-6xl font-nexa text-red-500">Our Signature Dishes</h2>
                    {/* Right Accent */}
                    <SignatureDishTacoRight className="w-[6vw] h-auto ml-4" />
                </div>
                <SignatureDishesCarousel />
            </div>

            {/* Bottom Divider */}
            <div className="relative bottom-[-3vw] w-full overflow-x-hidden">
                <TacoTuesdayDivider className="w-[120vw] h-auto" />
            </div>
        </section>
    );
};

export default SignatureDishesSection;