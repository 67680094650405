import React from 'react';
import { Link } from 'react-router-dom';

const FooterLogo: React.FC = () => (
  <Link to="/" className="">
  <div className="flex items-center">
    <img src="https://redz-bucket.s3.us-west-2.amazonaws.com/logo.webp" alt="Yona Redz Logo" className="h-48 w-auto" />
  </div>
  </Link>
);

export default FooterLogo;