import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as TacoAccentLeft } from '../../../assets/images/Taco Accent Left.svg';
import { ReactComponent as TacoAccentRight } from '../../../assets/images/Taco Accent Right.svg';
import { ReactComponent as WelcomeSeperator } from '../../../assets/images/WelcomeSeperator.svg';

const WelcomeSection: React.FC = () => (
    <motion.div
        className="relative mb-4 mt-4"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
    >
        <h1 className="text-5xl md:text-6xl lg:text-7xl mb-4 font-nexa text-center text-red-500 relative">
            <TacoAccentLeft className="absolute -top-5 left-[-20px] md:left-[-40px] w-8 md:w-12 h-8 md:h-12" />
            WELCOME
            <TacoAccentRight className="absolute -top-5 right-[-20px] md:right-[-40px] w-8 md:w-12 h-8 md:h-12" />
        </h1>
        <WelcomeSeperator className="w-full items-center justify-center" />

    </motion.div>
    
);

export default WelcomeSection;