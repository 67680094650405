import React, { ReactNode } from 'react';

interface BackgroundWithPinataProps {
    children: ReactNode;
}

const BackgroundWithPinata: React.FC<BackgroundWithPinataProps> = ({ children }) => {
    return (
        <div className="relative bg-black text-redz-white pt-24 lg:pt-32">
            <img 
                src={"https://redz-bucket.s3.amazonaws.com/Pinata.svg"} 
                alt="Pinata" 
                className="absolute top-0 left-[30%] transform translate-x-[-20%] w-[10vw] lg:w-[7.5vw]" 
            />
            <div className="mt-[20vw] lg:mt-[15vw]">
                {children}
            </div>
        </div>
    );
};

export default BackgroundWithPinata;