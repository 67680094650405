import React from 'react';
import { ReactComponent as HearFromYouSVG} from '../../assets/images/ContactUs/HearFromYou.svg';
import ContactFormSVG from './ContactUsForm';
import Newsletter from './Newsletter';
import LocationSection from '../common/LocationSection';

const ContactUsForm: React.FC = () => {
    return (
        <section className="relative bg-black text-center py-[20vh]">
            {/* Hear From You SVG */}
            <div className="relative mx-auto max-w-[80%]">
                <HearFromYouSVG className="mx-auto w-full" />
            </div>

            {/* Description */}
            <p className="font-myriad text-redz-white text-lg lg:text-xl mt-8 mx-auto max-w-[90%] lg:max-w-[50%] px-4 lg:px-0">
                Whether you have questions, need more information, or want to discuss your event, we're here to help. At Yona Redz, we're committed to making your experience as smooth and enjoyable as possible. Reach out to us today—we’re always happy to assist.
            </p>



            {/* Contact Form */}
            <div className="relative mt-12 mb-24 px-[2vw] lg:px-[30vw]">
                <div className="relative flex justify-start lg:justify-center items-center mt-12 pt-[12vw]">
                    <img src={"https://redz-bucket.s3.amazonaws.com/FlyLikeAnEagle.svg"} alt="Fly Like An Eagle" className="absolute left-0 lg:left-[-15vw] top-[-10vw] lg:top-[10vw] w-24 lg:w-36" />
                </div>
                <ContactFormSVG />
            </div>

            <Newsletter />
            <LocationSection title="VISIT US!"
            description="We are excited to welcome you to Yona Redz! Our restaurant is conveniently located in the heart of Santa Barbara." 
        />        </section>
    );
};

export default ContactUsForm;