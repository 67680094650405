import React from 'react';
import ImageGallery from './ImageGallery';

const Gallery: React.FC = () => (
    <ImageGallery
        image1Url={"https://redz-bucket.s3.amazonaws.com/TacosHome.webp"}
        image2Url={"https://redz-bucket.s3.amazonaws.com/StreetTacos.webp"}
        image3Url={"https://redz-bucket.s3.amazonaws.com/LunchSpecial.webp"}
    />
);

export default Gallery;