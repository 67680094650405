import React from 'react';
import { toast } from 'react-toastify';
import NewsletterThankYou from '../pieces/common/NewsletterThankYou'; // Your SVG Component
import { makeAutoObservable } from 'mobx';
import API from '../api/client';

class NewsletterStore {
  email = '';
  loading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setEmail = (newEmail: string) => {
    this.email = newEmail;
  };

  submitEmail = async () => {
    if (!this.email || !/\S+@\S+\.\S+/.test(this.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    this.loading = true;

    try {
      const response = await API.post('/api/newsletter-signup/', { email: this.email });

      // Display the toast without any additional container restrictions
      toast(
        <NewsletterThankYou />, 
        {
          closeButton: false,
          position: "top-center",
          autoClose: 5000,
          className: "fixed inset-0 flex justify-center items-center", 
          bodyClassName: "flex justify-center items-center w-full h-full",
          onOpen: () => {
            // Retry function to find and style the toast element
            const retryFindToast = (retries = 10) => {
              const toastElement: HTMLElement | null = document.querySelector('.Toastify__toast-container--top-center');
              if (toastElement) {
                toastElement.style.left = 'auto';
                toastElement.style.transform = 'none';
              } else if (retries > 0) {
                console.log(retries)
                // Try again after a short delay
                setTimeout(() => retryFindToast(retries - 1), 50);
              }
            };
      
            // Start retrying to find the element
            retryFindToast();
          },
          style: { 
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            padding: 0,
            margin: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 8888,
          },
        }
      );
      console.log("Newsletter sign-up successful:", response.data);
    } catch (error) {
      toast.error("Failed to sign up. Please try again.");
      console.error("Error signing up for the newsletter:", error);
    } finally {
      this.loading = false;
    }
  };
}

const newsletterStore = new NewsletterStore();
export default newsletterStore;