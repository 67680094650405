import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as CulinaryCreations } from '../../../assets/images/Culinary Creations.svg';

const CulinaryCreationsSection: React.FC = () => (
    <motion.div
        className="mb-4 w-full flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3, duration: 1 }}
    >
        <CulinaryCreations className="w-3/4 h-auto" />
    </motion.div>
);

export default CulinaryCreationsSection;