import React from 'react';
import { motion } from 'framer-motion';
import {ReactComponent as ButtonImage} from '../../../assets/images/Event Space Button.svg';

const EventSpaceButton: React.FC = () => {
    return (
        <motion.a
            href="/contact-us"
            className="inline-block"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <ButtonImage className="w-full h-auto" />
        </motion.a>
    );
};

export default EventSpaceButton;