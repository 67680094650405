import React from 'react';

interface TitleWithSombreroProps {
    title: string;
}

const TitleWithSombrero: React.FC<TitleWithSombreroProps> = ({ title }) => {
    return (
        <div className="relative text-center mt-12 lg:mt-16">
            <img 
                src={"https://redz-bucket.s3.amazonaws.com/Sombrero.svg"} 
                alt="Sombrero" 
                className="absolute top-[-6vw] right-[35%] transform translate-x-[0%] w-[12vw] lg:w-[8vw]" 
            />
            <h2 className="font-nexa text-redz-white text-4xl lg:text-6xl uppercase leading-tight text-balance">
                {title}
            </h2>
            <img 
                src={"https://redz-bucket.s3.amazonaws.com/PrivateEventsUnderline.svg"} 
                alt="Underline" 
                className="mx-auto mt-4 w-[50%] lg:w-[40%]" 
            />
        </div>
    );
};

export default TitleWithSombrero;